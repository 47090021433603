import {
  authentication,
  createDirectus,
  realtime,
  rest,
} from '@directus/sdk'
import { appendResponseHeader, splitCookiesString } from 'h3'
import type { LaastClient } from '~/types/api'

export function createDirectusClient(): LaastClient {
  const config = useRuntimeConfig().public.directus
  const event = useRequestEvent()
  const reqHeaders = useRequestHeaders(['cookie'])
  const { $sentry } = useNuxtApp()

  const fetch = $fetch.create({
    // retry: false,
    // keepalive: import.meta.server,
    onRequest({ options }) {
      // Forward Sentry tracing headers
      const activeSpan = $sentry?.getActiveSpan()
      const rootSpan = activeSpan ? $sentry?.getRootSpan(activeSpan) : undefined

      const baggage = rootSpan
        ? $sentry?.spanToBaggageHeader(rootSpan)
        : undefined
      if (baggage) {
        options.headers = {
          ...options.headers,
          baggage,
        }
      }

      const sentryTrace = rootSpan
        ? $sentry?.spanToTraceHeader(rootSpan)
        : undefined
      if (sentryTrace) {
        options.headers = {
          ...options.headers,
          'sentry-trace': sentryTrace,
        }
      }

      // Forward cookies during ssr
      if (import.meta.server) {
        options.headers = {
          ...options.headers,
          ...reqHeaders,
        }
      }
    },
    onResponse({ response }) {
      if (import.meta.server) {
        const cookies = splitCookiesString(response.headers.get('set-cookie') ?? '')

        for (const cookie of cookies) {
          appendResponseHeader(event!, 'set-cookie', cookie)
        }
      }
    },
  })

  return createDirectus(config.rest.baseUrl, {
    globals: {
      fetch,
    },
  })
    .with(authentication('cookie', {
      credentials: 'include',
      autoRefresh: import.meta.browser,
    }))
    .with(rest({
      credentials: 'include',
    }))
    .with(realtime({
      authMode: 'handshake',
    }))
}

<script setup lang="ts">
const props = defineProps<{
  error: any
}>()

const title = computed(() => {
  if (props.error?.statusCode === 401) {
    return 'Non autorisé'
  }

  if (props.error?.statusCode === 404) {
    return 'Page non trouvée'
  }

  return 'Oups, une erreur est survenue'
})

const description = computed(() => {
  if (props.error?.statusCode === 401) {
    return 'Vous n\'êtes pas autorisé à accéder à cette page.'
  }

  if (props.error?.statusCode === 404) {
    return 'Impossible de trouver la page que vous cherchez, veuillez contacter un administrateur système ou réessayer plus tard.'
  }

  return 'Une erreur s\'est produite. Si le problème persiste, veuillez contacter un administrateur système ou réessayer plus tard.'
})

const handleError = () => clearError({ redirect: '/' })
const retry = () => clearError()

// Show/hide demo stack trace
const showStackTrace = ref(true)
</script>

<template>
  <div class="pb-16">
    <BasePlaceholderPage
      :title="title"
      :subtitle="description"
      image-size="md"
      class="relative !items-end"
    >
      <template #image>
        <img src="/img/logo/logo-full-colors.svg" alt="">
      </template>

      <div class="mt-4">
        <div
          class="absolute inset-x-0 top-1/3 -translate-y-1/2 text-[13rem] font-bold text-muted-400/20 dark:text-muted-400/10 sm:text-[20rem]"
        >
          <span>{{ props.error?.statusCode }}</span>
        </div>
        <div
          class="mx-auto flex w-full max-w-xs items-center justify-center gap-2"
        >
          <BaseButton
            shape="curved"
            class="mx-auto !h-12 w-full max-w-[160px] items-center gap-2"
            @click="handleError"
          >
            <Icon name="feather:arrow-left" />
            Retour à l'app
          </BaseButton>
          <BaseButton
            color="muted"
            shape="curved"
            class="mx-auto !h-12 w-full max-w-[160px]"
            @click="retry"
          >
            <Icon name="feather:refresh-cw" class="size-3" />
            <span>Recharger</span>
          </BaseButton>
        </div>
        
      </div>
    </BasePlaceholderPage>

    
  </div>
</template>

<style>
.stack {
  @apply text-sm;
}
.stack.internal {
  @apply opacity-100 ps-4 text-xs;
}
</style>

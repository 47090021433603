export function hydrateNumber(input: string | number | null | undefined) {
  return input !== undefined && input !== null
      ? Number(input)
      : input;
}

export function hydrateDate(input: string | null | undefined) {
  return typeof input === 'string'
    ? new Date(input)
    : input;
}
export default defineAppConfig({
  icon: {
    mode: 'svg',
  },
  nui: {
    BaseCard: {
      rounded: 'sm',
    },
    BaseAutocomplete: {
      rounded: 'sm',
    },
    BaseAutocompleteItem: {
      rounded: 'sm',
    },
    BaseButtonAction: {
      rounded: 'sm',
    },
    BaseButtonClose: {
      rounded: 'sm',
    },
    BaseButtonIcon: {
      rounded: 'sm',
    },
    BaseButton: {
      rounded: 'sm',
    },
    BaseButtonGroup: {
      rounded: 'sm',
    },
    BaseDropdown: {
      rounded: 'sm',
    },
    BaseDropdownItem: {
      rounded: 'sm',
    },
    BaseInput: {
      rounded: 'sm',
    },
    BaseSelect: {
      rounded: 'sm',
    },
    BaseInputNumber: {
      rounded: 'sm',
    },
    BaseInputFile: {
      rounded: 'sm',
    },
    BaseTextarea: {
      rounded: 'sm',
    },
    BasePagination: {
      rounded: 'sm',
    },
  },
})

export function stripEmailResponse(message?: string | null) {
  if (!message)
    return ''
  const lines = message.split('\n')
  const result: string[] = []

  for (const line of lines) {
    if (line.trim().startsWith('>'))
      break
    if (line.trim().endsWith('> a écrit :'))
      break
    if (line.trim().endsWith('> wrote:'))
      break
    result.push(line)
  }

  return result.join('\n').trim()
}

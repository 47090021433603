export default defineNuxtPlugin({
  name: 'tidio',
  async setup() {
    const config = useRuntimeConfig()
    const { user } = useDirectusAuth()
  
    function openTidio() {
      window.tidioChatApi?.show()
      window.tidioChatApi?.open()
    }
    window.tidioChatApi?.on('messageFromOperator', openTidio)
  
    if (!config.public.demoMode) {
      watchEffect(() => {
        if (!user.value) {
          return
        }
        window.tidioChatApi?.setVisitorData({
          distinct_id: user.value.id,
          email: user.value.email,
          name: formatFullname(user.value),
          tags: [
            `role:${user.value?.role?.name}`,
            `instance:${document.location.host}`,
          ],
        })
      })
    }
  
    return {
      provide: {
        openTidio,
      },
    }
  }
})

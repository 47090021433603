import type { Discount } from '@laast-io/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { hydrateDiscount } from '~/utils/hydrate/discount'

type DiscountItem = ReturnType<typeof hydrateDiscount>

export const useDiscounts = defineStore('discounts', () => {
  const items = ref<DiscountItem[]>([])
  const loaded = ref(false)

  const hasItems = computed(() => items.value.length > 0)

  // sort
  const sortedItems = computed(() => {
    return items.value.sort((a, b) => {
      if (!a.sort)
        return -1
      if (!b.sort)
        return 1

      return a.sort - b.sort
    })
  })

  const forItems = computed(() => {
    return items.value.filter(item => item.allow_items)
  })
  const forServices = computed(() => {
    return items.value.filter(item => item.allow_services)
  })

  async function fetchItems() {
    const { $sentry } = useNuxtApp()
    const $directus = useDirectusSdk()

    try {
      const result = await $directus.request(
        readItems('discount', {
          limit: -1,
          fields: ['*'],
          sort: ['sort'],
        }),
      )

      if (result) {
        items.value = result.map(hydrateDiscount)
      }
      else {
        throw new Error('Something went wrong while fetching discounts')
      }

      if (!loaded.value) {
        loaded.value = true
      }
    }
    catch (error) {
      $sentry.captureException(error)
    }
  }

  async function createDiscount(input: Partial<Omit<Discount, 'id'>>) {
    const $directus = useDirectusSdk()
    const { $posthog } = useNuxtApp()
    const item = await $directus.request(createItem('discount', {
      ...input,
      label: input.label?.trim(),
    }))
    $posthog?.capture('settings_discount_create')

    if (!item) {
      throw new Error('Something went wrong while creating the discount')
    }

    items.value.push(item as DiscountItem)

    return item
  }

  async function updateDiscount(id: string, input: Partial<Omit<Discount, 'id'>>) {
    const $directus = useDirectusSdk()
    const { $posthog } = useNuxtApp()
    const item = await $directus.request(updateItem('discount', id, {
      ...input,
      label: input.label?.trim(),
      id: undefined,
    }))
    $posthog?.capture('settings_discount_update')

    if (!item) {
      throw new Error('Something went wrong while updating the discount')
    }

    const index = items.value.findIndex(item => item.id === id)
    items.value[index] = item as DiscountItem

    return item
  }

  function getById(id?: string | null | Discount) {
    if (!id)
      return
    const _id = typeof id === 'string' ? id : id.id
    return items.value.find(item => item.id === _id)
  }

  function getByLabel(label?: string | null | Discount) {
    if (!label)
      return
    const _label = typeof label === 'string' ? label : label.label
    return items.value.find(item => item.label === _label)
  }

  return {
    fetchItems,
    create: createDiscount,
    update: updateDiscount,
    // createItem: createDiscount,
    // exists,
    getById,
    getByLabel,
    loaded,
    forItems,
    forServices,
    items,
    hasItems,
    sortedItems,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDiscounts, import.meta.hot))
}

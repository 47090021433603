import type { LaastCurrentUser } from '../utils/user'
import { inviteUser as _inviteUser, acceptUserInvite, passwordRequest, passwordReset } from '@directus/sdk'
import { joinURL } from 'ufo'
import { fetchUser as _fetchUser } from '../utils/user'

export function useDirectusAuth() {
  const $directus = useDirectusSdk()
  const config = useRuntimeConfig().public.directus
  const user = useState<null | LaastCurrentUser>('laast-user', () => shallowRef(null))

  const isSuperAgent = computed(() =>
    user.value?.role?.admin_access === true
    || user.value?.role?.name === 'Super Agent',
  )

  async function fetchUser() {
    try {
      user.value = await _fetchUser($directus)
    }
    catch {
      user.value = null
    }
  }

  async function login(email: string, password: string) {
    const result = await $directus.login(email, password)
    await fetchUser()
    return result
  }

  async function logout() {
    user.value = null
    return await $directus.logout()
  }

  async function refresh() {
    return await $directus.refresh()
  }

  async function resetPassword(token: string, password: string) {
    return await $directus.request(passwordReset(token, password))
  }
  async function acceptInvite(token: string, password: string) {
    const decoded = parseJwt(token)
    if (!decoded.email) {
      throw new Error('Invalid token')
    }

    await $directus.request(acceptUserInvite(token, password))
    return await login(decoded.email, password)
  }
  async function updateProfileDetails(data: {
    first_name?: string
    last_name?: string
  }) {
    user.value = await $directus.request(updateMe(data, {
      fields: [
        'id',
        'email',
        'first_name',
        'last_name',
        {
          role: [
            'id',
            'name',
            'admin_access',
          ],
          avatar: [
            'id',
            'width',
            'height',
          ],
        },
      ],
    }))
  }

  async function requestPasswordReset(email: string) {
    const resetUrl = getRedirectUrl(config.auth.redirect.resetPassword)
    return await $directus.request(passwordRequest(email, resetUrl))
  }
  async function inviteUser(email: string, role: string) {
    const inviteUrl = getRedirectUrl(config.auth.redirect.inviteAgent)
    return await $directus.request(_inviteUser(email, role, inviteUrl))
  }
  function getRedirectUrl(path: string) {
    return joinURL(config.rest.nuxtBaseUrl, path)
  }

  return {
    user,
    isSuperAgent,
    fetchUser,
    login,
    logout,
    refresh,
    resetPassword,
    inviteUser,
    acceptInvite,
    updateProfileDetails,
    requestPasswordReset,
  }
}

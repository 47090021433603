// ensure the session is refreshed when the app is started
export default defineNuxtPlugin({
  name: 'refresh-stores',
  dependsOn: ['directus:auth'],
  async setup() {
    const { user } = useDirectusAuth()

    await initPublicStores()

    watch(user, async (value) => {
      if (value) {
        await initStores()
      }
      else {
        clearStores()
      }
    }, { immediate: true })
  },
})

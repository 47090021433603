import type { FormInput as CreateFormInput } from '@/utils/client/forms/device-category/create'
import type { DeviceCategory } from '@laast-io/types'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useDeviceCategory = defineStore('device-category', () => {
  const items = ref<DeviceCategory[]>([])
  const loaded = ref(false)

  const hasItems = computed(() => items.value.length > 0)

  // sort categories by names with localeCompare
  const sortedItems = computed(() => {
    return items.value.sort((a, b) => {
      if (!a.name)
        return -1
      if (!b.name)
        return 1

      return a.name.localeCompare(b.name)
    })
  })

  async function fetchItems() {
    const $directus = useDirectusSdk()
    const { $sentry } = useNuxtApp()

    try {
      const result = await $directus.request(
        readItems('device_category', {
          limit: -1,
          fields: ['*'],
        }),
      )

      if (result) {
        items.value = result
      }
      else {
        throw new Error('Something went wrong while fetching device categories')
      }

      if (!loaded.value) {
        loaded.value = true
      }
    }
    catch (error) {
      $sentry.captureException(error)
    }
  }

  async function createCategoryItem(input: CreateFormInput) {
    const $directus = useDirectusSdk()
    const { $posthog } = useNuxtApp()
    $posthog?.capture('device_category_create')
    const item = await $directus.request(
      createItem('device_category', {
        ...input,
        name: input.name?.trim(),
      }),
    )

    if (!item) {
      throw new Error('Something went wrong while creating the device category')
    }

    items.value.push(item as DeviceCategory)

    return item
  }

  function exists(id?: string) {
    if (!id)
      return false
    return items.value.some(item => item.id === id)
  }

  function getById(id?: string | null | DeviceCategory) {
    if (!id)
      return
    const _id = typeof id === 'string' ? id : id.id
    return items.value.find(item => item.id === _id)
  }

  function getBreadcrumb(from?: string | null | DeviceCategory) {
    if (!from)
      return []

    const breadcrumb: DeviceCategory[] = []

    let parentId: string | undefined = typeof from === 'string' ? from : from.id

    while (parentId) {
      const parent = getById(parentId)
      if (!parent) {
        break
      }
      breadcrumb.push(parent)
      parentId = parent?.parent as string | undefined
    }

    breadcrumb.reverse()

    return breadcrumb
  }

  return {
    fetchItems,
    createItem: createCategoryItem,
    exists,
    getById,
    getBreadcrumb,
    loaded,
    items,
    hasItems,
    sortedItems,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceCategory, import.meta.hot))
}

import type { ShopSettings } from "@laast-io/types";
import { hydrateNumber } from "./common";

export function hydrateShopSettings(
  input: any
) {
  return {
    ...input as ShopSettings,

    default_tax_ratio: hydrateNumber(input.default_tax_ratio),
    hour_close: hydrateNumber(input.hour_close),
    hour_open: hydrateNumber(input.hour_open),
    hour_precision: hydrateNumber(input.hour_precision),
  }
}
import type { ToastApp } from '#components'
import type {
  NinjaToasterBaseProps,
  NinjaToasterInstance,
} from '@cssninja/nuxt-toaster'
import type { ComponentProps } from 'vue-component-type-helpers'

interface ToasterOptions {
  message: string
  title?: string
}

export function useToaster() {
  const $nt = useNuxtApp().$nt as NinjaToasterInstance

  return {
    push(props: ComponentProps<typeof ToastApp>, options?: NinjaToasterBaseProps, clear = true) {
      if (clear) {
        $nt.clearAll()
      }
      $nt.showComponent('ToastApp', {
        props,
        options,
      })
    },
    /**
     * @deprecated
     */
    info(options: ToasterOptions) {
      $nt.clearAll()
      $nt.show(() => h(resolveComponent('ToastDefault'), options)).catch(
        () => {},
      )
    },
    /**
     * @deprecated
     */
    success(options: ToasterOptions) {
      $nt.clearAll()
      $nt.show(() => h(resolveComponent('ToastSuccess'), options)).catch(
        () => {},
      )
    },
    /**
     * @deprecated
     */
    error(options: ToasterOptions) {
      $nt.clearAll()
      $nt.show(() => h(resolveComponent('ToastDanger'), options)).catch(() => {})
    },
    clear() {
      $nt.clearAll()
    },
  }
}

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"theme-color","content":"#f4f4f5"},{"name":"robots","content":"none"}],"link":[{"rel":"icon","type":"image/svg+xml","href":"/favicon.svg"},{"rel":"apple-touch-icon","href":"/apple-touch-icon.png"}],"style":[],"script":[{"innerHTML":"(function(){const o=window,a=document.documentElement,t=window.localStorage,m=[\"dark\",\"light\"],c=t&&t.getItem&&t.getItem(\"nuxt-color-mode\")||\"system\";let l=c===\"system\"?s():c;const n=document.querySelector('meta[name=\"theme-color\"]');n&&n.setAttribute(\"content\",l===\"dark\"?\"#1c1917\":\"#f4f4f5\");function r(e){return o.matchMedia(\"(prefers-color-scheme\"+e+\")\")}function s(){if(o.matchMedia&&r(\"\").media!==\"not all\"){for(const e of m)if(r(\":\"+e).matches)return e}return\"light\"}})();"},{"src":"//code.tidio.co/b925y0mah3vjtksdfdwggszikx6jonqp.js","tagPosition":"bodyClose","async":true},{"tagPosition":"bodyClose","innerHTML":"(function(){function i(){(function(){window.tidioChatApi.hide(),window.tidioChatApi.on(\"close\",function(){window.tidioChatApi.hide()})})()}window.tidioChatApi?window.tidioChatApi.on(\"ready\",i):document.addEventListener(\"tidioChat-ready\",i)})();"}],"noscript":[],"viewport":"width=device-width, initial-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = "https://cdn.laast.io/app/v1.8.17/public"

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"deep":false,"value":null,"errorValue":null}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false

export const chunkErrorEvent = "vite:preloadError"
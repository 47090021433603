export function capitalize(string_?: string | null) {
  if (!string_)
    return ''
  return string_.charAt(0).toUpperCase() + string_.slice(1)
}

export function formatFullname(
  user?: {
    first_name?: string | null
    last_name?: string | null
  } | null,
) {
  return [user?.first_name, user?.last_name].filter(Boolean).join(' ')
}
export function formatFullnameList(
  user?: {
    first_name?: string | null
    last_name?: string | null
  } | null,
) {
  return [user?.last_name, user?.first_name].filter(Boolean).join(' ')
}


export function formatInitials(
  user?: {
    first_name?: string | null
    last_name?: string | null
  } | null,
) {
  return [user?.first_name?.charAt(0), user?.last_name?.charAt(0)]
    .filter(Boolean)
    .join('')
}

const serviceWordsBlacklist = [
  // / ?appareil ?/i,
  /changement(?: de l'| de l’| du | de la )?/i,
  /intervention(?: non )?/i,
  /remplacement(?: de l'| de l’| du | de la )?/i,
  /r[e|é]paration(?: de l'| de l’| du | de la )?/i,
]

export function formatServiceShortName(name: string) {
  if (!name)
    return ''

  let short = name

  for (const word of serviceWordsBlacklist) {
    short = short.replace(word, '')
  }

  return capitalize(short.trim())
}

export function formatFileSize(bytes: any, si = true, dp = 1) {
  const thresh = si ? 1000 : 1024

  if (Math.abs(bytes) < thresh) {
    return `${bytes} B`
  }

  const units = si
    ? ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
  let u = -1
  const r = 10 ** dp

  do {
    bytes /= thresh
    ++u
  } while (
    Math.round(Math.abs(bytes) * r) / r >= thresh
    && u < units.length - 1
  )

  return `${bytes.toFixed(dp)} ${units[u]}`
}

import type { Provider } from '@laast-io/types'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const useProviders = defineStore('providers', () => {
  const items = ref<Provider[]>([])
  const loaded = ref(false)

  const hasItems = computed(() => items.value.length > 0)

  // sort categories by names with localeCompare
  const sortedItems = computed(() => {
    return items.value.sort((a, b) => {
      if (!a.name)
        return -1
      if (!b.name)
        return 1

      return a.name.localeCompare(b.name)
    })
  })

  async function fetchItems() {
    const $directus = useDirectusSdk()
    const { $sentry } = useNuxtApp()

    try {
      const result = await $directus.request(
        readItems('provider', {
          limit: -1,
          fields: ['id', 'name'],
        }),
      )

      if (result) {
        items.value = result as Provider[]
      }
      else {
        throw new Error('Something went wrong while fetching providers')
      }

      if (!loaded.value) {
        loaded.value = true
      }
    }
    catch (error) {
      $sentry.captureException(error)
    }
  }

  async function createProvider(input: Partial<Omit<Provider, 'id'>>) {
    const $directus = useDirectusSdk()
    const { $posthog } = useNuxtApp()
    const item = await $directus.request(createItem('provider', {
      ...input,
      name: input.name?.trim(),
    }))
    $posthog?.capture('provider_create')

    if (!item) {
      throw new Error('Something went wrong while creating the provider')
    }

    items.value.push(item as Provider)

    return item
  }

  function exists(id?: string) {
    if (!id)
      return false
    return items.value.some(item => item.id === id)
  }

  function getById(id?: string | null | Provider) {
    if (!id)
      return
    const _id = typeof id === 'string' ? id : id.id
    return items.value.find(item => item.id === _id)
  }

  return {
    fetchItems,
    createItem: createProvider,
    exists,
    getById,
    loaded,
    items,
    hasItems,
    sortedItems,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useProviders, import.meta.hot))
}

<script setup lang="ts">
const route = useRoute()
const settings = useShopSettings()
const { $posthog } = useNuxtApp()
const color = useColorMode()

const unreadState = useState('total_messages_unread', () => 0)

watch(() => color.preference, () => {
  $posthog?.capture('app_theme_change', {
    theme: color.preference,
  })
})

onMounted(() => {
  document.documentElement.classList.add('ready')
})

const appTitle = computed(() => {
  return [settings.data?.name, 'Laast']
    .filter(Boolean)
    .join(' - ')
    .trim()
})
const titlePrefix = computed(() => {
  let prefix = ''
  if (unreadState.value > 0) {
    prefix = `(${unreadState.value})`
  }

  return prefix
})

useHead(() => ({
  title: () => {
    return [titlePrefix.value, route.meta.title].filter(Boolean).join(' ')
  },
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} - ${appTitle.value}` : appTitle.value
  },
  htmlAttrs: {
    lang: 'fr',
  },
  link: [
    {
      rel: 'manifest',
      href: '/manifest_laast.webmanifest',
    },
  ],
}))
</script>

<template>
  <div>
    
    <NuxtLoadingIndicator color="rgb(var(--color-primary-600))" />
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

export default defineNuxtRouteMiddleware((to) => {
  if (to.meta.admin !== true)
    return

  const { isSuperAgent } = useDirectusAuth()

  if (!isSuperAgent.value) {
    return navigateTo({
      path: '/',
    })
  }
})

<script setup lang="ts">
const props = defineProps<{
  error: any
}>()
</script>

<template>
  <div class="min-h-screen overflow-y-auto bg-muted-100 pt-8 dark:bg-muted-900">
    <AppError :error="props.error" />
  </div>
</template>

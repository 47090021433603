export default defineNuxtPlugin({
  name: 'toaster',
  async setup(nuxtApp) {
    const theme = {
      maxToasts: 1,
      transition: {
        enterActiveClass: 'transition duration-200 ease-out',
        enterFromClass: 'transform translate-y-full opacity-0',
        enterToClass: 'transform translate-y-0 opacity-100',
        leaveActiveClass: 'transition duration-200 ease-in',
        leaveFromClass: 'transform translate-y-0 opacity-100',
        leaveToClass: 'transform translate-y-full opacity-0',
      },
      containerClass: [
        'fixed',
        'inset-0',
        'pointer-events-none',
        'p-4',
        'flex',
        'flex-col-reverse',
        'overflow-hidden',
        'z-[99999]',
        'items-center',
        'gap-2',
        'space-y-3',
      ],
      wrapperClass: [
        'pointer-events-auto',
        'focus:outline-none',
        'cursor-pointer',
        'rounded',
        'outline-slate-300',
        'outline-offset-2',
        'focus:outline',
        'focus:outline-2',
        'focus-within:outline',
        'focus-within:outline-2',
      ],
    }
    const nt = createNinjaToaster({
      duration: 6000,
      dismissible: true,
      theme,
    })
  
    nuxtApp.vueApp.provide('nt', nt)
  
    return {
      provide: {
        nt,
      },
    }
  }
})

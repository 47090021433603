import type { WebSocketInterface } from '@directus/sdk'
import { joinURL } from 'ufo'
import type { LaastClient } from '~/types/api'

let ws: WebSocketInterface | undefined

export default defineNuxtPlugin({
  name: 'realtime',
  dependsOn: ['directus:auth'],
  async setup() {
    const { user } = useDirectusAuth()

    watchEffect(() => {
      if (!user.value) {
        return
      }

      connect()

      return () => {
        if (ws) {
          ws.close()
          ws = undefined
        }
      }
    })
  },
})

async function connect() {
  const { $sentry } = useNuxtApp()
  const $directus = useDirectusSdk()

  try {
    if (!ws) {
      ws = await $directus.connect()
    }

    subscribe($directus)
  }
  catch (error) {
    $sentry.captureException(error)
  }
}

async function subscribe($directus: LaastClient) {
  const { $sentry } = useNuxtApp()

  subscribeToCustomerMessages($directus).catch((error) => {
    $sentry.captureException(error)
  })
  subscribeToInterventions($directus).catch((error) => {
    $sentry.captureException(error)
  })
}

async function subscribeToInterventions($directus: LaastClient) {
  const { user } = useDirectusAuth()

  const { $sentry } = useNuxtApp()
  const { subscription, unsubscribe } = await $directus.subscribe('intervention', {
    query: {
      fields: ['id', 'user_updated'],
      // filter: {
      //   user_updated: {
      //     _neq: user.value?.id,
      //   },
      // },
    },
  })

  watchOnce(user, () => {
    unsubscribe()
  })

  for await (const item of subscription) {
    if (!item || item.event === 'init')
      continue

    const route = useRoute()

    if (route.path === '/') {
      refreshNuxtData(['interventions-kanban']).catch((error) => {
        $sentry.captureException(error)
      })
    }
    else if (route.path.startsWith('/agenda')) {
      refreshNuxtData(['interventions-agenda-unscheduled', 'interventions-agenda-scheduled']).catch((error) => {
        $sentry.captureException(error)
      })
    }
  }
}

async function subscribeToCustomerMessages($directus: LaastClient) {
  const { user } = useDirectusAuth()

  const { subscription, unsubscribe } = await $directus.subscribe('customer_message', {
    event: 'create',
    query: {
      fields: [
        'id',
        'from',
        'is_unread',
        'message',
        'customer',
        'date_created',
        'original_sender',
        // {
        //   customer: [
        //     'id',
        //     'first_name',
        //     'last_name',
        //     'email',
        //   ],
        // },
      ],
      filter: {
        from: {
          _eq: 'customer',
        },
        is_unread: {
          _eq: true,
        },
      },
    },
  })
  watchOnce(user, () => {
    unsubscribe()
  })

  for await (const item of subscription) {
    if (item.event === 'create' && item.data?.[0]?.message && item.data?.[0]?.customer) {
      const inboxUrl = joinURL(`/inbox`, item.data[0].customer as string)
      const unreadState = useState('total_messages_unread', () => 0)
      const toaster = useToaster()
      const route = useRoute()
      const { $sentry } = useNuxtApp()

      // refreshNuxtData(`inbox-unread-messages`)
      unreadState.value += 1

      if (route.path.startsWith('/inbox')) {
        refreshNuxtData(`inbox-conversations`).catch((error) => {
          $sentry.captureException(error)
        })

        if (route.path === inboxUrl) {
          refreshNuxtData(`customer-messages:${item.data[0].customer}`).catch((error) => {
            $sentry.captureException(error)
          })
          return
        }
      }

      const message = stripEmailResponse(item.data[0].message)
      const from = item.data[0].original_sender

      toaster.push({
        title: from ? `Nouveau message de ${from}` : `Nouveau message reçu`,
        message,
        color: 'primary',
        // icon: 'ph:mailbox',
        closable: true,
        nowrap: true,
        classes: {
          wrapper: 'min-w-[300px]',
          message: 'line-clamp-1',
        },
        actions: [
          {
            label: 'Voir le message',
            icon: 'ph:arrow-right',
            props: {
              size: 'sm',
              to: inboxUrl,
              color: 'primary',
              variant: 'pastel',
              onClick: () => {
                toaster.clear()
              },
            },
          },
        ],
      }, {
        dismissible: false,
        duration: 5000,
      }, false)
    }
  }
}

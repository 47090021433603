import validate from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth from "/usr/src/app/apps/frontend/modules/directus/runtime/middleware/auth.ts";
import admin from "/usr/src/app/apps/frontend/modules/directus/runtime/middleware/admin.ts";
import manifest_45route_45rule from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth,
  admin,
  manifest_45route_45rule
]
export const namedMiddleware = {}
import type { FormInput as CreateFormInput } from '@/utils/client/forms/device-category-group/create'
import type { DeviceCategoryGroup } from '@laast-io/types'
import { acceptHMRUpdate, defineStore } from 'pinia'

/**
 * @deprecated
 */
export const useDeviceCategoryGroup = defineStore(
  'device-category-group',
  () => {
    const deviceCategory = useDeviceCategory()
    const items = ref<DeviceCategoryGroup[]>([])
    const loaded = ref(false)

    const hasItems = computed(() => items.value.length > 0)

    // sort group by level
    const sortedItems = computed(() => {
      return items.value.sort((a, b) => {
        if (a.level === b.level) {
          return 0
        }
        else if (typeof a.level !== 'number') {
          return -1
        }
        else if (typeof b.level === 'number') {
          return b.level - a.level
        }
        else {
          return 1
        }
      })
    })

    const maxLevel = computed(() => {
      let max = 0

      for (const item of items.value) {
        if (typeof item.level !== 'number')
          continue
        if (item.level > max) {
          max = item.level
        }
      }
      return max
    })
    const minLevel = computed(() => {
      let min = Number.POSITIVE_INFINITY

      for (const item of items.value) {
        if (typeof item.level !== 'number')
          continue
        if (item.level < min) {
          min = item.level
        }
      }

      return min
    })

    function getFromDepth(depth: number) {
      const idx = maxLevel.value - depth
      return items.value.find(item => item.level === idx)
    }

    async function fetchItems() {
      const $directus = useDirectusSdk()
      const { $sentry } = useNuxtApp()

      try {
        const result = await $directus.request(
          readItems('device_category_group', {
            limit: -1,
            fields: ['*'],
          }),
        )

        if (result) {
          items.value = result
        }
        else {
          throw new Error(
            'Something went wrong while fetching device category groups',
          )
        }

        if (!loaded.value) {
          loaded.value = true
        }
      }
      catch (error) {
        $sentry.captureException(error)
      }
    }

    async function createCategoryGroupItem(input: CreateFormInput) {
      const $directus = useDirectusSdk()

      // find the highest level group
      const currentHighestGroup = items.value.find(
        item => item.level === maxLevel.value,
      )

      let currentHighestGroupItems: string[] = []
      if (currentHighestGroup?.id) {
        const topCategeories = await $directus.request(
          readItems('device_category', {
            fields: ['id'],
            filter: {
              device_category_group: currentHighestGroup.id,
            },
          }),
        )
        if (topCategeories) {
          currentHighestGroupItems = topCategeories.map(item => item.id)
        }
      }

      const item = await $directus.request(
        createItem('device_category_group', {
          ...input,
          name: input.name?.trim(),
        }),
      )

      if (!item) {
        throw new Error(
          'Something went wrong while creating the category group',
        )
      }

      // create new category, and migrate all the items from the previous group
      if (currentHighestGroupItems.length > 0) {
        const newParent = await $directus.request(
          createItem('device_category', {
            name: `Nouveau "${input.name?.trim()}"`,
            device_category_group: item.id,
          }),
        )
        await $directus.request(
          updateItems('device_category', currentHighestGroupItems, {
            parent: newParent.id,
          }),
        )

        // add new category to the device category store
        deviceCategory.fetchItems()
      }

      items.value.push(item as DeviceCategoryGroup)

      return item
    }

    return {
      fetchItems,
      createItem: createCategoryGroupItem,
      getFromDepth,
      loaded,
      items,
      minLevel,
      maxLevel,
      hasItems,
      sortedItems,
    }
  },
)

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useDeviceCategoryGroup, import.meta.hot))
}

import { default as agentsrz2wEiyvfvMeta } from "/usr/src/app/apps/frontend/pages/admin/agents.vue?macro=true";
import { default as imports3kcMHgMlMMeta } from "/usr/src/app/apps/frontend/pages/admin/import.vue?macro=true";
import { default as settingsI36bqNL4SaMeta } from "/usr/src/app/apps/frontend/pages/admin/settings.vue?macro=true";
import { default as adminH4kH3xMUOyMeta } from "/usr/src/app/apps/frontend/pages/admin.vue?macro=true";
import { default as agendaoHvrxxQQ1vMeta } from "/usr/src/app/apps/frontend/pages/agenda.vue?macro=true";
import { default as forgothuCSmQQMaBMeta } from "/usr/src/app/apps/frontend/pages/auth/forgot.vue?macro=true";
import { default as indexzVLwYBew4bMeta } from "/usr/src/app/apps/frontend/pages/auth/index.vue?macro=true";
import { default as invite1ZYnV39UkyMeta } from "/usr/src/app/apps/frontend/pages/auth/invite.vue?macro=true";
import { default as recoverCdOsUkA11IMeta } from "/usr/src/app/apps/frontend/pages/auth/recover.vue?macro=true";
import { default as devices275xFbYyTpMeta } from "/usr/src/app/apps/frontend/pages/catalog/devices.vue?macro=true";
import { default as indexM17fMe90auMeta } from "/usr/src/app/apps/frontend/pages/catalog/index.vue?macro=true";
import { default as itemsr06G57gvckMeta } from "/usr/src/app/apps/frontend/pages/catalog/items.vue?macro=true";
import { default as out_45of_45stocks1rK8fTWjOKMeta } from "/usr/src/app/apps/frontend/pages/catalog/out-of-stocks.vue?macro=true";
import { default as spare_45parts9Sh4VwauwiMeta } from "/usr/src/app/apps/frontend/pages/catalog/spare-parts.vue?macro=true";
import { default as catalogRY19H9fdrcMeta } from "/usr/src/app/apps/frontend/pages/catalog.vue?macro=true";
import { default as customersEFit0E6UHUMeta } from "/usr/src/app/apps/frontend/pages/customers.vue?macro=true";
import { default as indexvaHhjvKs3UMeta } from "/usr/src/app/apps/frontend/pages/dashboard/index.vue?macro=true";
import { default as _91id_93oNAT3gVeefMeta } from "/usr/src/app/apps/frontend/pages/inbox/[id].vue?macro=true";
import { default as indexhKTYU5dtN1Meta } from "/usr/src/app/apps/frontend/pages/inbox/index.vue?macro=true";
import { default as inbox7WfHfVsUU2Meta } from "/usr/src/app/apps/frontend/pages/inbox.vue?macro=true";
import { default as indexzG4qEZDAVaMeta } from "/usr/src/app/apps/frontend/pages/index.vue?macro=true";
import { default as printrBgApYbcD2Meta } from "/usr/src/app/apps/frontend/pages/invoice/[interventionId]/[id]/print.vue?macro=true";
import { default as ordersX7PTzaHcXHMeta } from "/usr/src/app/apps/frontend/pages/orders.vue?macro=true";
import { default as printKdCvMeurD1Meta } from "/usr/src/app/apps/frontend/pages/quote/[id]/print.vue?macro=true";
export default [
  {
    name: "admin",
    path: "/admin",
    meta: adminH4kH3xMUOyMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/admin.vue"),
    children: [
  {
    name: "admin-agents",
    path: "agents",
    meta: agentsrz2wEiyvfvMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/admin/agents.vue")
  },
  {
    name: "admin-import",
    path: "import",
    meta: imports3kcMHgMlMMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/admin/import.vue")
  },
  {
    name: "admin-settings",
    path: "settings",
    meta: settingsI36bqNL4SaMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/admin/settings.vue")
  }
]
  },
  {
    name: "agenda",
    path: "/agenda",
    meta: agendaoHvrxxQQ1vMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/agenda.vue")
  },
  {
    name: "auth-forgot",
    path: "/auth/forgot",
    meta: forgothuCSmQQMaBMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/auth/forgot.vue")
  },
  {
    name: "auth",
    path: "/auth",
    meta: indexzVLwYBew4bMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/auth/index.vue")
  },
  {
    name: "auth-invite",
    path: "/auth/invite",
    meta: invite1ZYnV39UkyMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/auth/invite.vue")
  },
  {
    name: "auth-recover",
    path: "/auth/recover",
    meta: recoverCdOsUkA11IMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/auth/recover.vue")
  },
  {
    name: catalogRY19H9fdrcMeta?.name,
    path: "/catalog",
    meta: catalogRY19H9fdrcMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/catalog.vue"),
    children: [
  {
    name: "catalog-devices",
    path: "devices",
    meta: devices275xFbYyTpMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/catalog/devices.vue")
  },
  {
    name: "catalog",
    path: "",
    meta: indexM17fMe90auMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/catalog/index.vue")
  },
  {
    name: "catalog-items",
    path: "items",
    meta: itemsr06G57gvckMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/catalog/items.vue")
  },
  {
    name: "catalog-out-of-stocks",
    path: "out-of-stocks",
    meta: out_45of_45stocks1rK8fTWjOKMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/catalog/out-of-stocks.vue")
  },
  {
    name: "catalog-spare-parts",
    path: "spare-parts",
    meta: spare_45parts9Sh4VwauwiMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/catalog/spare-parts.vue")
  }
]
  },
  {
    name: "customers",
    path: "/customers",
    meta: customersEFit0E6UHUMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/customers.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexvaHhjvKs3UMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/dashboard/index.vue")
  },
  {
    name: inbox7WfHfVsUU2Meta?.name,
    path: "/inbox",
    meta: inbox7WfHfVsUU2Meta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/inbox.vue"),
    children: [
  {
    name: "inbox-id",
    path: ":id()",
    meta: _91id_93oNAT3gVeefMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/inbox/[id].vue")
  },
  {
    name: "inbox",
    path: "",
    meta: indexhKTYU5dtN1Meta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/inbox/index.vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexzG4qEZDAVaMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/index.vue")
  },
  {
    name: "invoice-interventionId-id-print",
    path: "/invoice/:interventionId()/:id()/print",
    meta: printrBgApYbcD2Meta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/invoice/[interventionId]/[id]/print.vue")
  },
  {
    name: "orders",
    path: "/orders",
    meta: ordersX7PTzaHcXHMeta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/orders.vue")
  },
  {
    name: "quote-id-print",
    path: "/quote/:id()/print",
    meta: printKdCvMeurD1Meta || {},
    component: () => import("/usr/src/app/apps/frontend/pages/quote/[id]/print.vue")
  }
]
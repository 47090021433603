import type { PaymentMethod } from '@laast-io/types'
import { acceptHMRUpdate, defineStore } from 'pinia'

export const usePaymentMethods = defineStore('payment-methods', () => {
  const items = ref<PaymentMethod[]>([])
  const loaded = ref(false)

  const hasItems = computed(() => items.value.length > 0)

  // sort
  const sortedItems = computed(() => {
    return items.value.sort((a, b) => {
      if (!a.sort)
        return -1
      if (!b.sort)
        return 1

      return a.sort - b.sort
    })
  })

  const favorites = computed(() => {
    return items.value.filter(item => item.is_favorite)
  })
  const others = computed(() => {
    return items.value.filter(item => !item.is_favorite)
  })

  async function fetchItems() {
    const $directus = useDirectusSdk()
    const { $sentry } = useNuxtApp()
    try {
      const result = await $directus.request(
        readItems('payment_method', {
          limit: -1,
          fields: ['*'],
          sort: ['sort'],
        }),
      )

      if (result) {
        items.value = result as PaymentMethod[]
      }
      else {
        throw new Error('Something went wrong while fetching payements methods')
      }

      if (!loaded.value) {
        loaded.value = true
      }
    }
    catch (error) {
      $sentry.captureException(error)
    }
  }

  async function createPaymentMethod(input: Partial<Omit<PaymentMethod, 'id'>>) {
    const $directus = useDirectusSdk()
    const { $posthog } = useNuxtApp()
    const item = await $directus.request(createItem('payment_method', {
      ...input,
      label: input.label?.trim(),
    }))
    $posthog?.capture('settings_payment_method_create')

    if (!item) {
      throw new Error('Something went wrong while creating the payement method')
    }

    items.value.push(item as PaymentMethod)

    return item
  }

  async function updatePaymentMethod(id: string, input: Partial<Omit<PaymentMethod, 'id'>>) {
    const $directus = useDirectusSdk()
    const { $posthog } = useNuxtApp()
    const item = await $directus.request(updateItem('payment_method', id, {
      ...input,
      label: input.label?.trim(),
      id: undefined,
    }))
    $posthog?.capture('settings_payment_method_update')

    if (!item) {
      throw new Error('Something went wrong while updating the payement method')
    }

    const index = items.value.findIndex(item => item.id === id)
    items.value[index] = item as PaymentMethod

    return item
  }

  async function saveOrder(orders: Partial<PaymentMethod>[]) {
    const $directus = useDirectusSdk()
    const { $posthog } = useNuxtApp()


    $posthog?.capture('settings_payment_method_update_order')

    for (let i = 0; i < orders.length; i++) {
      const item = orders[i]
      if (!item?.id)
        continue

      await $directus.request(updateItem('payment_method', item.id, {
        sort: i,
      }))
    }

    await fetchItems()
  }

  function getById(id?: string | null | PaymentMethod) {
    if (!id)
      return
    const _id = typeof id === 'string' ? id : id.id
    return items.value.find(item => item.id === _id)
  }

  function getByLabel(label?: string | null | PaymentMethod) {
    if (!label)
      return
    const _label = typeof label === 'string' ? label : label.label
    return items.value.find(item => item.label === _label)
  }

  return {
    fetchItems,
    create: createPaymentMethod,
    update: updatePaymentMethod,
    getById,
    getByLabel,
    saveOrder,
    loaded,
    favorites,
    others,
    items,
    hasItems,
    sortedItems,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePaymentMethods, import.meta.hot))
}

export default defineNuxtPlugin({
  name: 'realtime',
  dependsOn: ['toaster', 'tidio'],
  async setup() {
    const config = useRuntimeConfig()
  
    if (!config.public.demoMode) {
      return
    }
    const route = useRoute()
    const toaster = useToaster()

    watch(() => route.path, () => {
      if (route.meta.auth === false) {
        return
      }

      toaster.push({
        title: 'Mode demo',
        color: 'primary',
        message: 'Certaines fonctionnalités ne sont pas accessibles dans cette version de démonstration.',
        // icon: 'ph:info',
        closable: true,
        actions: [
          {
            label: 'Support',
            icon: 'ph:question',
            props: {
              size: 'sm',
              onClick: () => {
                const { $openTidio } = useNuxtApp()
                toaster.clear()
                $openTidio()
              },
            },
          },
          {
            label: 'Créez votre espace',
            icon: 'ph:arrow-square-in',
            props: {
              size: 'sm',
              color: 'primary',
              variant: 'pastel',
              to: `https://tally.so/r/w5G4eQ`,
              target: '_blank',
              onClick: () => {
                toaster.clear()
              },
            },
          },
        ],
      }, {
        dismissible: false,
        duration: 5000,
      })
    })
  }
})
import { readMe } from '@directus/sdk'
import type { LaastRestClient } from '~/types/api'

export type LaastCurrentUser = Awaited<ReturnType<typeof fetchUser>>

export async function fetchUser($directus: LaastRestClient) {
  return await $directus.request(readMe({
    fields: [
      'id',
      'email',
      'first_name',
      'last_name',
      {
        role: [
          'id',
          'name',
          'admin_access',
        ],
        avatar: [
          'id',
          'width',
          'height',
        ],
      },
    ],
  }))
}

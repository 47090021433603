export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.directive<HTMLElement, boolean | undefined>('focus', {
    mounted(el, { value }) {
      if (value === false) {
        return
      }

      // If the element is not focusable, find the first focusable child
      if (el && el.tabIndex === -1) {
        const focusable = el.querySelector<HTMLElement>(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        )
        if (focusable) {
          let listener: (() => void) | undefined = () => {
            focusable.focus()
            if (listener) {
              focusable.removeEventListener('transitionend', listener)
              listener = undefined
            }
          }
          focusable.addEventListener('transitionend', listener)
          focusable.focus()
        }
        else {
          console.warn('No focusable child found for element', el)
        }
        return
      }

      el.focus()
    },
    getSSRProps() {
      return {}
    },
  })
})

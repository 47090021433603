import revive_payload_client_0FBU2yYwCw from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_wzAd63aFC9 from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8U4unViWEN from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _01_sentry_client_2rh1pDAIty from "/usr/src/app/apps/frontend/plugins/01.sentry.client.ts";
import payload_client_HOH6e2eZXj from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_DG4txjX0DQ from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_Opgp0aG3Yu from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_uMP06ICGLK from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ASGcABqDKm from "/usr/src/app/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_rollup@4.24.0_typescript@5.6.3_vue@3.5.12_typescript@5.6.3__webpack-sources@3.2.3/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/usr/src/app/apps/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_Og5PaWmRbe from "/usr/src/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.7.5_encoding@0.1.13_eslint@9.12.0_jiti@2.3.3_pcxjkwt2xqjg4nrhc2jtq37afq/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_52GYdETYhE from "/usr/src/app/node_modules/.pnpm/@nuxt+icon@1.5.6_magicast@0.3.5_vite@5.4.8_@types+node@22.7.5_terser@5.34.1__vue@3.5.12_types_tabttje3vmkaidqv63svk7ba4m/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_cMK4tQrGor from "/usr/src/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import pwa_icons_plugin_LnrhIJeMG7 from "/usr/src/app/apps/frontend/.nuxt/pwa-icons-plugin.ts";
import pwa_client_yvh1qU70oO from "/usr/src/app/node_modules/.pnpm/@vite-pwa+nuxt@0.10.5_magicast@0.3.5_rollup@4.24.0_vite@5.4.8_@types+node@22.7.5_terser@5.34._qljlepwpt5vvdw35gwaot37njm/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.js";
import auth_l3hBn8iHZ2 from "/usr/src/app/apps/frontend/modules/directus/runtime/plugins/auth.ts";
import client_bvN9kI2JkZ from "/usr/src/app/apps/frontend/modules/directus/runtime/plugins/client.ts";
import date_fns_client_3ls07ysum5 from "/usr/src/app/apps/frontend/plugins/date-fns.client.ts";
import demo_mode_client_89F4bAtDjD from "/usr/src/app/apps/frontend/plugins/demo-mode.client.ts";
import directives_8CcCirWtnE from "/usr/src/app/apps/frontend/plugins/directives.ts";
import posthog_client_fUucxKWhZE from "/usr/src/app/apps/frontend/plugins/posthog.client.ts";
import pwa_client_PLDhtd2phb from "/usr/src/app/apps/frontend/plugins/pwa.client.ts";
import realtime_client_zoDOfIXbuN from "/usr/src/app/apps/frontend/plugins/realtime.client.ts";
import refresh_session_7qdqtZHABh from "/usr/src/app/apps/frontend/plugins/refresh-session.ts";
import tidio_client_CExgt3bj1j from "/usr/src/app/apps/frontend/plugins/tidio.client.ts";
import toaster_3RwauQEG5B from "/usr/src/app/apps/frontend/plugins/toaster.ts";
export default [
  revive_payload_client_0FBU2yYwCw,
  unhead_wzAd63aFC9,
  router_8U4unViWEN,
  _01_sentry_client_2rh1pDAIty,
  payload_client_HOH6e2eZXj,
  navigation_repaint_client_DG4txjX0DQ,
  check_outdated_build_client_Opgp0aG3Yu,
  chunk_reload_client_uMP06ICGLK,
  plugin_vue3_ASGcABqDKm,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Og5PaWmRbe,
  plugin_52GYdETYhE,
  plugin_client_cMK4tQrGor,
  pwa_icons_plugin_LnrhIJeMG7,
  pwa_client_yvh1qU70oO,
  auth_l3hBn8iHZ2,
  client_bvN9kI2JkZ,
  date_fns_client_3ls07ysum5,
  demo_mode_client_89F4bAtDjD,
  directives_8CcCirWtnE,
  posthog_client_fUucxKWhZE,
  pwa_client_PLDhtd2phb,
  realtime_client_zoDOfIXbuN,
  refresh_session_7qdqtZHABh,
  tidio_client_CExgt3bj1j,
  toaster_3RwauQEG5B
]
import type { ShopSettings } from '@laast-io/types'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { hydrateShopSettings } from '~/utils/hydrate/shop-settings'

export const useShopSettings = defineStore('shop-settings', () => {
  const loaded = ref(false)
  const data = ref<ReturnType<typeof hydrateShopSettings>>()

  async function fetchData() {
    const $directus = useDirectusSdk()
    const { $sentry } = useNuxtApp()

    try {
      data.value = await $directus.request(
        readSingleton('shop_settings', {
          fields: ['*'],
        }),
      )
      .then(hydrateShopSettings)

      if (!loaded.value) {
        loaded.value = true
      }
    }
    catch (error) {
      $sentry.captureException(error)
    }
  }

  return {
    data,
    loaded,
    fetchData,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useShopSettings, import.meta.hot))
}

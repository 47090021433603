import posthog from 'posthog-js'

declare module '#app' {
  interface NuxtApp {
    $posthog?: typeof posthog
  }
}

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig().public.posthog
  const { user } = useDirectusAuth()
  const router = useRouter()

  const client = posthog.init(config.key, {
    api_host: config.host,
    person_profiles: 'identified_only',
    capture_pageview: false, // we add manual pageview capturing below
    loaded: (posthog) => {
      if (import.meta.env.MODE === 'development')
        posthog.debug()
    },
  })

  client?.group('instance', window.location.hostname)

  router.afterEach((to) => {
    nextTick(() => {
      client?.capture('$pageview', {
        current_url: to.fullPath,
      })
    })
  })

  watchEffect(() => {
    if (!user.value || !client)
      return

    client.identify(user.value.id, {
      email: user.value.email,
      name: formatFullname(user.value),
      instance: window.location.hostname,
    })
  })

  return {
    provide: {
      posthog: client || undefined,
    },
  }
})

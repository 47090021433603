import * as Sentry from '@sentry/vue'
import { isDevelopment } from 'std-env'

import { version } from '~/package.json'

export default defineNuxtPlugin({
  enforce: 'pre',
  setup(nuxt) {
    const config = useRuntimeConfig().public.sentry
    const router = useRouter()
    const { user } = useDirectusAuth()

    // if (!config?.dsn) {
    //   if (isDevelopment) {
    //     console.warn('Sentry client plugin disabled: no DSN')
    //   }
    //   return
    // }

    // if (isDevelopment && config.enabled) {
    //   console.info('Sentry browser plugin enabled')
    // }

    Sentry.init({
      app: nuxt.vueApp,
      dsn: config.dsn,
      environment: config.env || 'development',
      debug: config.enabled && config.debug,
      release: `laast-frontend-nuxt@${version}`,
      enabled: config.enabled,
      integrations: [
        Sentry.browserTracingIntegration({
          router,
        }),
        Sentry.replayIntegration({
          flushMinDelay: 5000,
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      trackComponents: true,
      tracesSampleRate: 1,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
    })

    // Continue the trace from the server-side rendered page
    // const sentryTrace = document.querySelector<HTMLMetaElement>('meta[name=sentry-trace]')?.content
    // const baggage = document.querySelector<HTMLMetaElement>('meta[name=baggage]')?.content

    // const propagationContext = propagationContextFromHeaders(
    //   sentryTrace,
    //   baggage,
    // )
    // Sentry.getCurrentScope().setPropagationContext(propagationContext)

    nuxt.vueApp.mixin(
      Sentry.createTracingMixins({
        trackComponents: true,
        timeout: 2000,
        hooks: ['activate', 'mount', 'update'],
      }),
    )
    Sentry.attachErrorHandler(nuxt.vueApp, {
      logErrors: isDevelopment,
      attachProps: true,
      trackComponents: true,
      timeout: 2000,
      hooks: ['create', 'activate', 'mount', 'update', 'destroy'],
    })

    watchEffect(() => {
      const email = user.value?.email || undefined
      Sentry.setUser?.({
        email,
      })
    })

    return {
      provide: {
        sentry: Sentry,
      },
    }
  },
})

declare module '#app' {
  interface NuxtApp {
    $sentry: typeof Sentry
  }
}

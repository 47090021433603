import { createDirectusClient } from '../utils/sdk.client.js'

export default defineNuxtPlugin({
  name: 'directus:client',
  setup() {
    const directus = createDirectusClient()

    return {
      provide: {
        directus,
      },
    }
  },
})

export default defineNuxtPlugin({
  name: 'directus:auth',
  dependsOn: ['directus:client'],
  async setup(nuxtApp) {
    const { user, fetchUser, refresh } = useDirectusAuth()

    try {
      const auth = await refresh()
      if (!user.value && auth?.access_token) {
        await fetchUser()
      }
    }
    catch (error) {
      nuxtApp?.$sentry?.captureException(error)
    }
  },
})

export async function initPublicStores(force = false) {
  const tasks: Promise<void>[] = []

  // load shop settings
  const settings = useShopSettings()
  if (force || !settings.loaded) {
    tasks.push(settings.fetchData())
  }
  // prevent unnecessary promise.all
  if (tasks.length === 0)
    return

  await Promise.all(tasks)
}

export async function initStores(force = false) {
  const tasks: Promise<void>[] = []

  // load payment methods
  const payementMethods = usePaymentMethods()
  if (force || !payementMethods.loaded) {
    tasks.push(payementMethods.fetchItems())
  }

  const discounts = useDiscounts()
  if (force || !discounts.loaded) {
    tasks.push(discounts.fetchItems())
  }

  // load device categories
  const deviceCategory = useDeviceCategory()
  if (force || !deviceCategory.loaded) {
    tasks.push(deviceCategory.fetchItems())
  }

  // load device category groups
  const deviceCategoryGroup = useDeviceCategoryGroup()
  if (force || !deviceCategoryGroup.loaded) {
    tasks.push(deviceCategoryGroup.fetchItems())
  }

  // load providers
  const providers = useProviders()
  if (force || !providers.loaded) {
    tasks.push(providers.fetchItems())
  }

  // prevent unnecessary promise.all
  if (tasks.length === 0)
    return

  await Promise.all(tasks)
}

export function clearStores() {
  // clear device categories
  const deviceCategory = useDeviceCategory()
  deviceCategory.items = []
  deviceCategory.loaded = false

  // clear device category groups
  const deviceCategoryGroup = useDeviceCategoryGroup()
  deviceCategoryGroup.items = []
  deviceCategoryGroup.loaded = false

  const providers = useProviders()
  providers.items = []
  providers.loaded = false

  const discounts = useDiscounts()
  discounts.items = []
  discounts.loaded = false

  const payementMethods = usePaymentMethods()
  payementMethods.items = []
  payementMethods.loaded = false
}

import type { RouterConfig } from '@nuxt/schema'

// https://router.vuejs.org/api/#routeroptions
export default <RouterConfig>{
  scrollBehavior: (to, _, savedPosition) => {
    // Scroll to heading on click
    if (to.hash) {
      if (to.hash === '#') {
        return {
          top: 0,
          // behavior: 'smooth',
        }
      }

      const element = document.querySelector(to.hash)

      // vue-router does not incorporate scroll-margin-top on its own.
      if (element) {
        const top = Number.parseFloat(getComputedStyle(element).scrollMarginTop)
        if (element instanceof HTMLElement) {
          element.focus()
        }

        return {
          el: to.hash,
          // behavior: 'smooth',
          top,
        }
      }

      return {
        el: to.hash,
        // behavior: 'smooth',
      }
    }

    // Scroll to top of window
    return savedPosition || { top: 0 }
  },
}

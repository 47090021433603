import { defineNuxtPlugin } from '#app/nuxt'
import { LazyPanelAccount, LazyPanelCustomerCreate, LazyPanelCustomerEdit, LazyPanelDeviceModelCreate, LazyPanelDeviceModelEdit, LazyPanelInterventionCreate, LazyPanelInterventionEdit, LazyPanelItemCreate, LazyPanelItemEdit, LazyPanelLoader, LazyPanelServiceCreate, LazyPanelServiceEdit, LazyPanelSparePartCreate, LazyPanelSparePartEdit, LazyPanelFormItem, LazyPanelFormStock, LazyPanelFormIntervention, LazyPanelFormInterventionCart, LazyPanelFormInterventionCartItem, LazyPanelFormInterventionCustomer, LazyPanelFormInterventionCustomerDevice, LazyPanelFormInterventionDevice, LazyPanelFormInterventionPlannedService, LazyPanelFormInterventionPlannedServiceItem, LazyPanelFormInterventionPlannedServiceItemSparePart, LazyPanelFormService, LazyPanelFormServiceSparePart, LazyPanelFormServiceSparePartItem, LazyPanelFormSparePart, LazyPanelFormSparePartService, LazyPanelFormSparePartServiceItem, LazyPanelLinkCustomerDeviceCreate, LazyPanelLinkCustomerDeviceEdit, LazyToastApp, LazyToastDanger, LazyToastDefault, LazyToastSuccess, LazyIcon } from '#components'
const lazyGlobalComponents = [
  ["PanelAccount", LazyPanelAccount],
["PanelCustomerCreate", LazyPanelCustomerCreate],
["PanelCustomerEdit", LazyPanelCustomerEdit],
["PanelDeviceModelCreate", LazyPanelDeviceModelCreate],
["PanelDeviceModelEdit", LazyPanelDeviceModelEdit],
["PanelInterventionCreate", LazyPanelInterventionCreate],
["PanelInterventionEdit", LazyPanelInterventionEdit],
["PanelItemCreate", LazyPanelItemCreate],
["PanelItemEdit", LazyPanelItemEdit],
["PanelLoader", LazyPanelLoader],
["PanelServiceCreate", LazyPanelServiceCreate],
["PanelServiceEdit", LazyPanelServiceEdit],
["PanelSparePartCreate", LazyPanelSparePartCreate],
["PanelSparePartEdit", LazyPanelSparePartEdit],
["PanelFormItem", LazyPanelFormItem],
["PanelFormStock", LazyPanelFormStock],
["PanelFormIntervention", LazyPanelFormIntervention],
["PanelFormInterventionCart", LazyPanelFormInterventionCart],
["PanelFormInterventionCartItem", LazyPanelFormInterventionCartItem],
["PanelFormInterventionCustomer", LazyPanelFormInterventionCustomer],
["PanelFormInterventionCustomerDevice", LazyPanelFormInterventionCustomerDevice],
["PanelFormInterventionDevice", LazyPanelFormInterventionDevice],
["PanelFormInterventionPlannedService", LazyPanelFormInterventionPlannedService],
["PanelFormInterventionPlannedServiceItem", LazyPanelFormInterventionPlannedServiceItem],
["PanelFormInterventionPlannedServiceItemSparePart", LazyPanelFormInterventionPlannedServiceItemSparePart],
["PanelFormService", LazyPanelFormService],
["PanelFormServiceSparePart", LazyPanelFormServiceSparePart],
["PanelFormServiceSparePartItem", LazyPanelFormServiceSparePartItem],
["PanelFormSparePart", LazyPanelFormSparePart],
["PanelFormSparePartService", LazyPanelFormSparePartService],
["PanelFormSparePartServiceItem", LazyPanelFormSparePartServiceItem],
["PanelLinkCustomerDeviceCreate", LazyPanelLinkCustomerDeviceCreate],
["PanelLinkCustomerDeviceEdit", LazyPanelLinkCustomerDeviceEdit],
["ToastApp", LazyToastApp],
["ToastDanger", LazyToastDanger],
["ToastDefault", LazyToastDefault],
["ToastSuccess", LazyToastSuccess],
["Icon", LazyIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})

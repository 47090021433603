export default defineNuxtRouteMiddleware((to) => {
  if (to.meta.auth === false)
    return

  const { user } = useDirectusAuth()

  if (!user.value) {
    return navigateTo({
      path: '/auth',
      query: { redirect: to.fullPath },
    })
  }
})

import type { Discount } from "@laast-io/types";
import { hydrateDate, hydrateNumber } from "./common";

export function hydrateDiscountRelation(
  input: any
) {
  if (typeof input !== 'object' || input === null) {
    return input as Discount['id'] | null | undefined
  }

  return hydrateDiscount(input)
}
export function hydrateDiscount(
  input: any
) {
  return {
    ...(input as Discount),


    // hydrate numbers
    value: hydrateNumber(input.value),

    // hydrate dates
    date_created: hydrateDate(input.date_created),
    date_updated: hydrateDate(input.date_updated),
  }
}